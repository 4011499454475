@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --tooltip-text-color: white;
  --tooltip-background-color: rgba(0, 0, 0, 0.4);
  --tooltip-margin: 10px;
  --tooltip-arrow-size: 6px;
}

body {
  background: url("../images/default-bg.jpeg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

/* UTILITY CLASSES */
.visible-text {
  text-shadow: 1px 1px 2px rgb(0 0 0 / 10%), -1px -1px 2px rgb(0 0 0 / 10%),
    -1px 1px 2px rgb(0 0 0 / 10%), 1px -1px 2px rgb(0 0 0 / 10%);
}

.search-type--active,
.search-type:hover {
  @apply font-bold;
  text-shadow: black 0px 0px 6px;
}

.capitalize {
  text-transform: uppercase;
}
.flow-content > * + * {
  margin-top: 10px;
}

.flow-content--lg > * + * {
  margin-top: 20px;
}

.max-w-280 {
  max-width: 280px;
}
.min-w-300 {
  min-width: 300px;
}
.width-90 {
  width: 90px;
}
/**
  COMPONENTS
  **/

/**STYLED CARD**/
.styled-card {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  width: 100%;
}

/**
  LAYOUT
  **/
.top-section {
  display: grid;
  gap: 20px;
  grid-template-columns: 20% 1fr;
  align-content: center;
  justify-items: center;
}

@media screen and (max-width: 900px) {
  .top-section {
    grid-template-columns: 1fr;
  }
}

.media-section {
  display: grid;
  grid-template-columns: 20% 1fr;
}

.trend-list {
  max-height: 85%;
}
.search-list {
  max-height: 60vh;
  min-height: 59vh;
  order: 3;
}

.custom-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: inherit;
  border-radius: 10px;
  box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%),
    inset -2px -2px 2px rgb(0 0 0 / 25%);
}

.custom-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
/**
LOADING SPINNER CSS
**/
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/**
side menu**/
.side-menu {
  padding: 5px 0px;
  border-radius: 0.3rem;
  background-color: rgba(28, 28, 28, 0.3);
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
}

.side-menu li {
  transition: all 500ms ease;
}

.side-menu li:hover {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.side-menu li.active {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

/**
TOOLTIP
 Custom properties */
/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  backdrop-filter: blur(10px);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

/* MEDIA CARD */
.media-detail {
  background: linear-gradient(to right, #e5e6e6 50%, transparent 100%);
}

/*RADIO BUTTONS*/
/** radio buttons **/
input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  @apply bg-green-600;
  box-shadow: 0px 0px 0px 2px green inset;
}

input[type="radio"]:checked + label {
  @apply text-green-600;
}

.imdb-logo{
  padding-bottom:15px;
}

.logo-text{
  padding-left:12px;
}

.h-perso {
  height: calc(100% - 0.5rem);
  overflow-y: hidden;
}

.fully-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexible-terms {
  width: 32%;
  min-width: 280px;
  max-width: 310px;
  display: flex;
  margin-top: 5px;
  align-items: center;
  justify-content: space-around;
}

.reversed {
  flex-direction: column-reverse;
  align-items: center;
  gap: 5px;
}

.order-66 {
  order: 2;
  margin-top: 10px;
}

.iconize{
  height: 100%;
  width: auto;
}

.img-logo {
  height: 100%!important;
  width: auto!important;
}

.padding-please {
  padding: 5rem 5rem 1rem 5rem;
}